@font-face {
  font-family: 'Inter';
  src: url('../../fonts/Inter-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Russo One';
  src: url('../../fonts/RussoOne-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto';
  src: url('../../fonts/Roboto-Regular.ttf') format('truetype');
}

.header {
  background-color: #F8F8F8;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.wrapper {
  padding-left: 20px;

}

.recommendation {
  color: #4D4D4D;
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  margin-top: 154px;
  margin-bottom: 39px;
}

.pad {

  padding-left: 132px;
  padding-right: 132px;
  width: 85%;
}

.subtitle {
  color: #4D4D4D;

  font-family: 'Inter';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 40px;
}

.img-block .img {
  width: 384px;
  /* Adjust width as needed */
  height: 302;
  /* Keeps the aspect ratio intact */
  border-radius: 12px;
  margin-left: 200px;
}

/* .img-block {
  width: 250px;
  height: 275px;
  margin-left: 200px;
  border-radius: 12px; */
/* } */


.image-title {
  display: flex;
  flex-direction: row;
}

.last-wrapper {
  border-right: none;
}

.wrapper-title {
  text-align: left;
}

.title {
  font-size: 42px;
  font-weight: 400;
  font-family: 'Russo One';
  color: #4D4D4D;
  margin-bottom: 15px;
  display: flex;
  justify-content: flex-start;

}

.arrow {
  font-size: 35px;
  margin-left: 5px;
  font-weight: 500;
}

.enroll-btn {
  /* Rectangle 33 */

  width: 247.01px;
  height: 68.94px;
  left: 132.2px;
  top: 412.02px;

  background: #1A92D6;
  mix-blend-mode: normal;
  border-radius: 12px;

  border-width: 0cap;


  display: flex;
  justify-content: center;
  align-items: center;

  margin-top: 56px;
  margin-bottom: 55px;
  margin-right: 30px;

  color: #FFF;
  font-family: 'Inter';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.number {
  width: 250px;
  font-family: 'Roboto';
  font-weight: 300;
  font-size: 18px;
  color: #4D4D4D;
}

.advantages {
  position: relative;
  width: 100%;
  height: 180px;
  border-radius: 12px;
  margin-top: 50px;
  margin-bottom: 80px;
  display: flex;
  justify-content: space-between;

}

.point {
  width: 325px;
  height: 180.674px;


  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 10px;
  background-color: white;
  border-radius: 12px;
}

.adv-block {
  width: 90%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.description {
  color: #4D4D4D;
  font-family: 'Inter';
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  margin-top: 10px;
}

.point-title {
  margin-top: 20px;
  font-size: 22px;
  color: #4D4D4D;
  font-family: 'Inter';
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.about {
  color: #4D4D4D;
  font-family: 'Inter';
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;


  margin-top: 124px;
  margin-bottom: 17px;
}

.line {
  height: 0px;
  width: 100%;
  border: 1px solid #DADADA;
  flex: 1;
}

.skills {
  display: flex;
  flex-direction: row;
  border: none;

}

.skill {
  border: none;
  margin-right: 15px;
  padding: 10px 30px;
  border-radius: 45px;


  background: rgba(26, 146, 214, 0.1);
  mix-blend-mode: normal;


  color: #4D4D4D;
  text-align: center;
  font-family: 'Inter';
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.drop {

  display: flex;
  align-items: center;
  position: absolute;
  right: 0;

  color: #C0C0C0;
  text-align: right;
  font-family: 'Roboto';
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-decoration-line: underline;


}

.btn-enr {
  display: flex;
  flex-direction: row;
  align-items: center;

}

.ttl-btn {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.sk-line {
  margin-right: 10px;
  display: flex;
  align-items: center;
  margin-top: 27px;
  margin-bottom: 17px;

  color: #4D4D4D;
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.module-item {

  padding: 20px 15px;
  border-left: #1A92D6 7.42px solid;
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
  padding: 30px 35px;
  margin-bottom: 20px;
  width: 100%;
  background-color: #FFFFFF;
  box-sizing: border-box;



}



.grey-desc {
  font-size: 25px;
  color: grey;
  margin-bottom: 30px;
  display: flex;
  align-items: baseline;
  width: 100%;
}

.module {
  display: flex;
  font-family: 'Roboto';
  align-items: center;
  position: relative;
}

.module-name {
  font-size: 22px;
  font-weight: 400;
  color: #838383;
  margin-right: 10px;

}

.module-desc {
  font-size: 20px;
  font-weight: 300;
  font-size: 20px;
  color: #4D4D4D;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.module-desc-title {

  color: #4D4D4D;
  font-family: 'Inter';
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.navigate {
  color: #838383;
  font-family: 'Inter';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 31px;
  margin-top: 77px;

}

.info-block {
  background-color: #1A92D6;
  /* padding: 20px; */
  width: 100%;
  height: 540px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* padding-left: 130px; */
}

.info-title {

  text-align: center;
  width: 100%;
  display: flex;
  margin-top: 70px;
  margin-bottom: 60px;


  color: #FFF;
  font-family: 'Inter';
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  /* 36px */
}

.info {
  display: flex;
  width: 31%;
  margin-bottom: 40px;
  margin-right: 130px;
  padding: 30px;

}

.info-icon {
  flex-shrink: 0;
  margin-right: 10px;
}

.info-text {
  display: flex;
  align-items: flex-start;
  color: #FFF;
  font-family: 'Inter';
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  /* 140% */
}

.info-container {
  display: flex;
  flex-wrap: wrap;
  /* width: 940px; */
}

.inf-wr {
  display: flex;
  flex-direction: column;
  margin-left: 132px;
}

.week {


  color: #1A92D6;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

}

/****MOBILE****/

@media (max-width: 500px) {

  .inf-wr {
    display: flex;
    flex-direction: column;
    padding: 0;
    margin: 0;
    width: 100%;
  }

  .info-container {

    display: flex;
    flex-direction: column;
    width: 100%;

  }

  .info-title {
    color: #FFF;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
    /* 24px */

    margin-bottom: 28px;
    margin-left: 29px;
    margin-top: 50px;
  }

  .info-icon {
    flex-shrink: 0;
    margin-right: 3px;
  }

  .info-text {
    width: 280px;
    height: 60px;


    color: #FFF;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    /* 142.857% */
  }

  .info {
    margin-bottom: 28px;
    width: 80%;
    margin-left: 29px;
    padding: 0;
    box-sizing: border-box;
  }

  .recommendation {
    display: none;
  }

  .pad {
    padding-left: 25px;
    padding-right: 25px;
  }

  .btn-enr {
    display: flex;
    flex-direction: column;
  }

  .title {
    text-align: left;
    font-size: 22px;
    width: 285.111px;
  }

  .enroll-btn {
    margin-top: 25px;
    width: 188.597px;
    height: 52.634px;
    flex-shrink: 0;

  }

  .number {
    text-align: left;
  }

  .info-block {
    width: 100%;
    height: 433px;
    display: flex;
    flex-direction: column;
  }

  .img-block {
    display: none;
  }

  .advantages {
    flex-direction: column;
    height: auto;
    background-color: #F8F8F8;
  }

  .point {
    width: 300px;
    height: 88.004px;
    flex-shrink: 0;

    border-radius: 4px;
    background: rgba(255, 255, 255, 0.70);

    margin-bottom: 5px;
  }

  .wrapper {
    border-right: none;
    /* padding: 20px; */
    margin-left: 0;
  }

  .wrapper-title {
    display: flex;
    align-items: center;

  }

  .point-title {
    margin-top: 0;
    text-align: left;
    color: #4D4D4D;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .description {
    color: #4D4D4D;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    /* margin-left: 56px; */
    line-height: normal;

  }

  .skills {
    flex-wrap: wrap;
  }

  .skill {
    font-size: 14px;
    margin-bottom: 10px;
    margin-right: 10px;
  }

  .module-item {
    padding: 20px 15px;
  }

  .module {
    flex-direction: column;
    text-align: left;
    align-items: start;
  }

  .grey-desc {
    margin-bottom: 10px;
  }

  .module-desc {
    flex-direction: column;
  }

  .module-desc-title {
    margin-top: 15px;
    margin-left: 35px;
    font-size: 14px;
  }

  .drop {
    position: relative;
    margin-top: 15px;
    margin-left: 35px;
    font-size: 14px;



  }
}