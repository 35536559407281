@font-face {
    font-family: 'Inter';
    src: url('../../fonts/Inter-Regular.ttf');
}

.alert{
    width: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #1A92D6;
    height: 200px;
    color: white;
    text-align: center;
    padding: 20px;

    color: #FFF;
    font-family: 'Roboto';
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
}