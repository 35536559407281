.dropdown {
    margin-top: 20px;
    padding-left: 155px;
    font-family: 'Roboto';
    font-size: 16px;
    font-weight: 300;
    color: #838383;
}

.content-title {
    text-align: left;
    display: flex;
    align-items: center;
    white-space: break-spaces;
    color: #4D4D4D;
    font-weight: 800;
    margin: 20px 0;
}

.content-field {
    white-space: break-spaces;
    color: #838383;
    text-align: left;
    margin-bottom: 10px;
}

.times {
    font-weight: 800;
}

@media (max-width: 500px) {
    .dropdown {
        padding-left: 35px;
    }
}