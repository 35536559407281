.rcw-conversation-container {
	max-width: none;
}

.rcw-input {
	max-width: 275px;
}

@media (max-width: 500px) {
	.rcw-input {
		max-width: none;
	}

}