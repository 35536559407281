/* Header.css */
.header-s {
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: #fff;
  padding: 10px 20px;
  width: 100%;
  height: 80px;
  flex-direction: row;
}

.logo {
  width: 116px;
  height: 20px;
}

.nav {
  display: flex;
  gap: 20px;
  font-size: 16px;
  margin-right: 120px;
  color: #4D4D4D;


  color: #4D4D4D;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.search-box input[type="search"] {
  border: none;
  width: 350px;
  height: 32px;

  border-radius: 6px;
  background: #F8F8F8;


  color: #C0C0C0;
font-family: Roboto;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: normal;
}

.input{
  padding-left: 20px;
}
@media (max-width: 500px) {

  .header-s {
    width: 100%;
    padding: 0;

  }

  .search-box {
    display: none;
  }

  .nav {
    display: none;
  }
}