.image {
    max-width: 100%;
    height: 194.961px;
    flex-shrink: 0;
    border-radius: 12px 12px 0px 0px;
}

.card-title, .card-subtitle {
    color: #4D4D4D;
    font-family: Inter;
    font-size: 16px;
    line-height: normal;
    padding-left: 32px;
    margin-top: 16px; 
    margin-bottom: 16px;
}

.card-title {
    font-weight: 600;
}

.card-subtitle {
    font-weight: 300;
}

.card {
    display: flex;
    flex-direction: column;
    border-radius: 12px;
    background: #FFF;
    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.08);
    margin: 10px; 
    width: 370px;
    height: 316.172px;
    flex-shrink: 0;
}

.cards {
    display: flex;
    flex-wrap: wrap; 
    justify-content: space-around; 
    align-items: stretch;
    width: 100%;
    box-sizing: border-box; 
    background-color: #F8F8F8;
    padding-left:132px;
padding-right: 132px;
}

@media (max-width: 768px) {
    .cards {
        justify-content: center; 
    }

    .card {
        max-width: 45%;
    }

    .card-title, .card-subtitle {
        padding-left: 16px; 
    }
}

@media (max-width: 500px) {
    .cards {
        padding: 16px; 
    }

    .card {
        max-width: 100%;
    }

    .card-title, .card-subtitle {
        padding-left: 16px;
    }
}
