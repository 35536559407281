.hide-on-mobile {
  display: none;
}

/* @media only screen and (max-width: 768px) { 
  .hide-on-mobile {
      display: block;
  }
} */

.App{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}